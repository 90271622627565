import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import { Record, RelatedRecords } from "common/types/records";
import { UTCDateRange } from "common/date-time/types";
import { hasTableOnly } from "common/functions/related-form-display";
import { mergeChain } from "common/merge";
import { TableWithForm } from "common/record/form/content/related/table-with-form";
import { RelatedTableWarning } from "common/record/form/content/related/table-with-form/warning";
import { RelatedEntityTable } from "common/record/related-entity-table";
import { getIgnoreColumns } from "common/record/related-entity-table/functions";
import { PartialForm, RelatedUiValue } from "common/record/types";
import {
  getRelatedRecords,
  omitVirtualPropsFromRecords,
  recordsById,
  recordToPayload,
} from "common/record/utils";
import { defaultDisplayTypes } from "common/types/related-form-display";
import { ValueProps } from "common/with-value-for";
import { PropTypes, TableWithFormValue } from "./table-with-form/types";
import { RelatedValue } from "./types";

type Props = PropTypes & ValueProps<RelatedValue>;

const defaultRecord = defaultFor<Record>();
const defaultRelated = defaultFor<RelatedRecords>();
const defaultRelatedUi = defaultFor<RelatedUiValue>();
const defaultPartial = defaultFor<PartialForm>();

export const getUiIsDirty = (props: Props): boolean =>
  props?.value?.related?.isDirty || false;

export const getRelatedRecordsByEntity = (props: Props, entityName: string) =>
  props?.value?.record?.related?.[entityName] || [];

export const getPartialFormWithRange = (
  partialForm: PartialForm,
  entityName: string,
  defaultDates: UTCDateRange,
) => {
  const entityPartialForm = partialForm?.[entityName] || {};
  if (!defaultDates) return entityPartialForm;
  const { from, to } = defaultDates;
  const { rangeFrom = from, rangeTo = to } = entityPartialForm;
  return R.mergeRight(entityPartialForm, { rangeFrom, rangeTo });
};

const markNewRecords = (record: Record) => ({
  ...record,
  isNew: !record.properties.id,
});

export class StandardRelated extends Component<Props> {
  static readonly displayName = "StandardRelated";

  onTableChange = (table: TableWithFormValue) => {
    const { entity, value, onChange } = this.props;
    const { record = defaultRecord } = value;
    const { related = defaultRelated } = record;
    const { records = [], form } = table;

    const entityName = entity.name;
    const origItemsById = recordsById(related[entityName]);

    const recordsWithoutVirtualColumns = omitVirtualPropsFromRecords(
      records,
      entity,
    );

    const formData = recordsWithoutVirtualColumns
      .map(markNewRecords)
      .map(recordToPayload);
    const updates = R.reject((r) => {
      const originalItem = origItemsById[r.properties.id];
      const payload = originalItem ? recordToPayload(originalItem) : undefined;
      return (
        payload &&
        R.equals(r.properties, payload.properties) &&
        R.equals(r.related, payload.related)
      );
    }, formData);

    const newValue = mergeChain(value)
      .prop("related")
      .set("isDirty", !!updates.length)
      .prop("form")
      .set(entityName, updates)
      .up()
      .prop("partialForm")
      .set(entity.name, form)
      .output();

    onChange(newValue);
  };

  render() {
    const {
      context,
      query,
      entity,
      children,
      orderBy,
      defaultDates,
      highlighted,
      parentEntity,
      displayTypes = defaultDisplayTypes,
      ignore,
      warning,
      widgetsMapper,
      isRecordDisabled,
      value,
      withLinks,
    } = this.props;
    const { record = defaultRecord, related = defaultRelatedUi } = value;
    const { partialForm = defaultPartial } = related;
    const entityName = entity.name;
    const records = getRelatedRecords(entityName, record, related.form);

    const form = getPartialFormWithRange(
      partialForm,
      entity.name,
      defaultDates,
    );

    return hasTableOnly(displayTypes) || value.record?.properties?.isDeleted ? (
      <RelatedEntityTable
        context={context}
        query={query}
        parentEntityName={parentEntity.name}
        entity={entity}
        recordId={record.properties.id}
        orderBy={orderBy}
        highlighted={highlighted}
        actions={undefined}
        withOwnerId={undefined}
        readOnly={true}
        ignore={getIgnoreColumns(ignore)}
        widgetsMapper={widgetsMapper}
        isRecordDisabled={isRecordDisabled}
        value={records}
        onChange={undefined}
        withLinks={withLinks}
      />
    ) : (
      <TableWithForm
        {...this.props}
        ignore={ignore}
        warning={
          warning ?? <RelatedTableWarning related={related} entity={entity} />
        }
        value={{ form, records }}
        onChange={this.onTableChange}
      >
        {children}
      </TableWithForm>
    );
  }
}
