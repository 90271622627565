import * as R from "ramda";
import { defaultFor } from "common";
import { deepEqual } from "common/component";
import { behaveAs } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { isConditionMonitoringFormValid } from "common/form/behavior-forms/condition-monitoring/functions";
import { FormValidation, GroupColumn, Layout } from "common/form/types";
import { merge3 } from "common/merge";
import { KeysOf, Properties } from "common/types/records";
import {
  columnWithDynamicValue,
  columnWithValue,
  isRecordSelfReference,
} from "common/validate";

const COLUMNS_TO_SKIP = ["sites"];

const areColumnValuesValid = (props: Properties, entity: Entity) =>
  !entity?.columns.some(
    (c) => columnWithValue(entity, c, props?.[c.name]).length,
  );

const areColumnWithDynamicValuesValid = (
  entities: Entities,
  props: Properties,
  entity: Entity,
) =>
  !entity?.columns.some(
    (c) => columnWithDynamicValue(entities, entity, c, props?.[c.name]).length,
  );

export const checkFormValidation = (formValidation: FormValidation) =>
  !formValidation ||
  !Object.values(formValidation?.fields ?? {}).some((f) => f.isValid === false);

export const validateGroupColumns = (
  cols: EntityColumn[],
  props: Properties,
  groupColumns: GroupColumn[] = [],
) => {
  const requiredColumns = groupColumns.filter((column) => {
    const col = R.find((c) => c.name === column.columnName, cols);
    return col && (column.required || col.required) && !col.readOnly;
  });

  return R.all((column) => !R.isNil(props[column.columnName]), requiredColumns);
};

const validateLayout = (
  cols: EntityColumn[],
  props: Properties,
  layout: Layout,
) =>
  !layout ||
  R.all(
    (section) => validateGroupColumns(cols ?? [], props, section.columns),
    layout.groups || [],
  );

export const hasAnyFieldFilledIn = (form: Properties) =>
  R.toPairs(form ?? defaultFor()).some(
    ([column, value]) => !COLUMNS_TO_SKIP.includes(column) && !R.isNil(value),
  );

const hasNoSelfReferencingColumnValues = (entity: Entity, props: Properties) =>
  !behaveAs("Tree", entity) ||
  !entity?.columns?.some((c) => {
    const value = props[c.name];
    return (
      entity.arguments.fkColumn === c.name &&
      isRecordSelfReference(entity.name, props?.id, c, value)
    );
  });

export const isBehaviorFormValid = (
  entity: Entity,
  props: Properties = defaultFor(),
) =>
  !behaveAs("ConditionMonitoring", entity) ||
  isConditionMonitoringFormValid(props);

// this ignores the check for hasAnyFieldFilledIn
export const isFormWithDynamicValuesValid = (
  entities: Entities,
  entity: Entity,
  layout: Layout,
  formValidation?: FormValidation,
  props: Properties = defaultFor(),
) =>
  areColumnWithDynamicValuesValid(entities, props, entity) &&
  checkFormValidation(formValidation) &&
  validateLayout(entity?.columns, props, layout) &&
  isBehaviorFormValid(entity, props);

export const isFormValid = (
  entity: Entity,
  layout: Layout,
  formValidation?: FormValidation,
  props: Properties = defaultFor(),
) =>
  areColumnValuesValid(props, entity) &&
  checkFormValidation(formValidation) &&
  validateLayout(entity?.columns, props, layout) &&
  hasAnyFieldFilledIn(props) &&
  isBehaviorFormValid(entity, props) &&
  hasNoSelfReferencingColumnValues(entity, props);

export const omitEmptyFields = (form: Properties) => {
  return R.pickBy((val) => !R.isNil(val), form);
};

export const isFormDirty = (
  defaultForm: Properties,
  defaultsInLayout: Properties,
  form: Properties,
  ignoredProperties: KeysOf<Properties> = [],
) => {
  const formDefaults = {
    ...defaultForm,
    ...defaultsInLayout,
  };
  const cleanForm = R.omit(ignoredProperties, form);
  const formWithFilledFields = omitEmptyFields(cleanForm);

  return R.isEmpty(formDefaults)
    ? hasAnyFieldFilledIn(formWithFilledFields)
    : !deepEqual(formDefaults, formWithFilledFields);
};

export const setColumnIsValid = (
  formValidation: FormValidation,
  columnName: string,
  isValid: boolean,
) => merge3("fields", columnName, "isValid", isValid, formValidation);
