import { Component, createRef } from "react";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { getColumn } from "common/entities";
import { isCustomOrSystemFk } from "common/entities/entity-column/functions";
import { type EntityColumn } from "common/entities/entity-column/types";
import { type Entity } from "common/entities/types";
import {
  type FormValidationProps,
  type LookupConfiguration,
  type MappedField,
} from "common/form/types";
import { LabelWidget } from "common/form/widget/label-widget";
import {
  getFkId,
  getSubFKTitle,
  isForeignKey,
} from "common/functions/foreign-key";
import { addFilterToQuery } from "common/query/filter";
import { type Context } from "common/types/context";
import { type FkValue, type ForeignKey } from "common/types/foreign-key";
import { type Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { AlertErrorTip } from "common/widgets/alert";
import { Selector } from "common/widgets/selector";
import { type SelectorOption } from "common/widgets/selector/types";
import { type ValueProps } from "common/with-value-for";
import {
  getEmptyOption,
  getLevelQuery,
  isEmptyOption,
  unwrap,
} from "./functions";

interface InternalPropTypes
  extends ValueProps<Properties>,
    FormValidationProps {
  context: Context;
  entity: Entity;
  index: number;
  mainFkColumnName: string;
  targetEntityName: string;
  mappedFields: MappedField[];
  previousLvlName: string;
  level: MappedField;
  unwrap: (value: FkValue) => ForeignKey;
  readOnly?: boolean;
}

interface StateType {
  resolvedValue?: ForeignKey;
  isOptionValid: boolean;
  options: ForeignKey[];
  menuIsOpen: boolean;
}

const getFkOptionLabel = (option: SelectorOption<ForeignKey>) =>
  isGroupedOption(option) ? option.label : getSubFKTitle(option.title);

const valueNeedsResolving = (column: EntityColumn, value: keyof Properties) =>
  value && column.dataType === "fk" && !isForeignKey(value as FkValue);

const getFkResolutionRequest = (
  context: Context,
  relatedEntity: Entity,
  levelValue: any,
) => {
  return searchApi(context.apiCall)
    .runQueryForLookup({
      entity: relatedEntity.name,
      query: addFilterToQuery(
        { name: "id", op: "eq", value: levelValue },
        relatedEntity.query,
      ),
    })
    .then((results: ForeignKey[]) => {
      return results.find((r) => r.id === levelValue);
    });
};

const getMatchingOption = (value: FkValue, options: ForeignKey[]) => {
  const valueId = getFkId(value);
  return valueId ? options?.find((option) => option.id === valueId) : undefined;
};

class InternalLevel extends Component<InternalPropTypes, StateType> {
  drilldownLevelRef = createRef<Selector<ForeignKey>>();
  state: StateType = {
    resolvedValue: undefined,
    isOptionValid: true,
    options: undefined,
    menuIsOpen: undefined,
  };

  componentDidMount() {
    const { value, previousLvlName } = this.props;
    if (!previousLvlName || !!value[previousLvlName]) this.fetchOptions();
  }

  componentDidUpdate(prevProps: InternalPropTypes) {
    if (this.props.index === 0) return;
    const { level, value, onChange, previousLvlName } = this.props;

    const previousChanged =
      value[previousLvlName] !== prevProps.value[previousLvlName];
    if (!previousChanged) return;

    if (value[previousLvlName]) {
      this.fetchOptions().then((options) => {
        if (options.length === 1) {
          // only 1 possible option, set it
          onChange({ ...value, [level.columnName]: options[0] });
        } else if (options.length > 1) {
          this.setState({ menuIsOpen: true });
        }
      });
    } else {
      this.setState({
        options: undefined,
        menuIsOpen: undefined,
        resolvedValue: undefined,
        isOptionValid: true,
      });
    }
  }

  fetchOptions = () => {
    const {
      index,
      targetEntityName,
      mappedFields,
      context,
      entity,
      value,
      unwrap,
      level: { columnName, targetColumnName },
    } = this.props;

    const query = getLevelQuery(
      targetEntityName,
      targetColumnName,
      mappedFields,
      value,
      index,
    );

    return searchApi(context.apiCall)
      .runQueryFkExpansion(query)
      .then((results: Properties[]) => {
        return results.map((properties) =>
          unwrap(properties[targetColumnName]),
        );
      })
      .then((options: ForeignKey[]) => {
        const levelColumn = getColumn(entity, columnName);
        const relatedEntity = context.entities[levelColumn.relatedEntity];
        const levelValue = value[columnName];

        const resolutionRequest = valueNeedsResolving(levelColumn, levelValue)
          ? getFkResolutionRequest(context, relatedEntity, levelValue)
          : Promise.resolve(undefined);

        return resolutionRequest.then((resolvedValue) => {
          const finalValue = resolvedValue ?? levelValue;
          const isOptionValid =
            !finalValue || !!getMatchingOption(finalValue, options);

          this.setState({ options, resolvedValue, isOptionValid });
          return options;
        });
      });
  };

  onLevelChange = (levelValue: ForeignKey) => {
    const { mappedFields, mainFkColumnName, value, index, level, onChange } =
      this.props;

    const fieldsToReset = mappedFields
      .slice(index + 1)
      .reduce((acc, f) => ({ ...acc, [f.columnName]: undefined }), {});

    onChange({
      ...value,
      ...fieldsToReset,
      [mainFkColumnName]: undefined, // main field/fk cleared too
      [level.columnName]: levelValue,
    });
    this.setState({ resolvedValue: undefined, isOptionValid: true });
  };

  getOptions = () => {
    const { value, level } = this.props;
    const { options, resolvedValue } = this.state;
    const levelValue = value[level.columnName];

    const selected =
      getMatchingOption(levelValue, options) || resolvedValue || levelValue;

    return { selected, options };
  };

  render() {
    const {
      entity,
      context,
      readOnly,
      level: { columnName },
      value,
    } = this.props;
    const { menuIsOpen, isOptionValid } = this.state;
    const { options, selected } = this.getOptions();
    const levelColumn = getColumn(entity, columnName);

    return (
      <VerticalField
        key={columnName}
        className={`qa-${columnName}`}
        label={getLocalizedName(levelColumn)}
        error={!isOptionValid}
        input={
          <ConditionalRequired
            isRequired={levelColumn?.required}
            value={isEmptyOption(selected) ? undefined : selected}
          >
            {readOnly ? (
              <div className="x-read-only-label-wrapper">
                <LabelWidget
                  context={context}
                  column={levelColumn}
                  value={value[columnName]}
                />
              </div>
            ) : (
              <Selector
                ref={this.drilldownLevelRef}
                getOptionLabel={getFkOptionLabel}
                allowClear={true}
                options={options}
                menuIsOpen={menuIsOpen}
                value={selected}
                onChange={this.onLevelChange}
              />
            )}
            {!isOptionValid ? (
              <AlertErrorTip
                message={_("The selected value is not a valid option")}
              />
            ) : undefined}
          </ConditionalRequired>
        }
      />
    );
  }
}

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  entity: Entity;
  index: number;
  mainFkColumnName: string;
  lookupConfiguration: LookupConfiguration;
  readOnly?: boolean;
}

export const DrilldownLevel = ({
  context,
  entity,
  index,
  readOnly,
  mainFkColumnName,
  lookupConfiguration,
  formValidation,
  onFormValidationChange,
  value,
  onChange,
}: PropTypes) => {
  const { targetEntity, mappedFields } = lookupConfiguration;
  const level = mappedFields[index];
  const levelColumn = getColumn(entity, level.columnName);
  const unwrapLevel = (value: FkValue) => {
    return value
      ? unwrap(value, isCustomOrSystemFk(levelColumn))
      : getEmptyOption();
  };

  return (
    <InternalLevel
      context={context}
      entity={entity}
      readOnly={readOnly}
      unwrap={unwrapLevel}
      mainFkColumnName={mainFkColumnName}
      targetEntityName={targetEntity}
      index={index}
      mappedFields={mappedFields}
      previousLvlName={mappedFields[index - 1]?.columnName}
      level={level}
      formValidation={formValidation}
      onFormValidationChange={onFormValidationChange}
      value={value}
      onChange={onChange}
    />
  );
};
