import { JSX } from "react";
import { UTCDateRange } from "common/date-time/types";
import { Entity } from "common/entities/types";
import {
  IsRecordDisabledFn,
  Layout,
  MapLayoutFn,
  MapWidgetPropsFn,
} from "common/form/types";
import { Action, ActionWithContent } from "common/query/table/types";
import { OrderField, Query } from "common/query/types";
import { Custom } from "common/record/form/content/related/types";
import { DetailUiValue, RelatedForm, Reload } from "common/record/types";
import { Context } from "common/types/context";
import { Properties, Record } from "common/types/records";
import { RelatedFormDisplayTypes } from "common/types/related-form-display";
import { GoFn } from "common/types/url";
import { OnChange } from "common/with-value-for";

export interface TableWithFormValue {
  form: RelatedForm;
  records: Record[];
}

export const defaultValue: TableWithFormValue = {
  form: undefined,
  records: undefined,
};

export interface PropTypes {
  context: Context;
  query: Query;
  parentEntity: Entity;
  entity: Entity;
  actions: Action[];
  recordId: any;
  recordDetail?: DetailUiValue;
  actionsWithContent?: ActionWithContent[];

  layoutMapper: MapLayoutFn;
  defaultForm: Properties;
  custom: Custom;
  orderBy: OrderField;
  isValid: (rf: RelatedForm) => boolean;
  withOwnerId?: boolean;
  children?: any;
  isTemplate?: boolean;
  onCancel?: () => void;
  defaultDates?: UTCDateRange;
  highlighted?: string[];
  displayTypes?: RelatedFormDisplayTypes;
  ignore?: string[];
  warning?: JSX.Element;
  widgetsMapper?: MapWidgetPropsFn;
  goTo?: GoFn;
  withLinks?: boolean;
  reload?: Reload;
  isRecordDisabled?: IsRecordDisabledFn;
}

export interface InjectedPropTypes {
  layout: Layout;
  custom: Custom;
  onSave: () => any;
  onCancel?: () => any;
  value: RelatedForm;
  onChange: OnChange<RelatedForm>;
}

export interface Injected<T extends RelatedForm> {
  layout: Layout;
  custom: Custom;
  onSave: () => any;
  value: T;
  onChange: OnChange<T>;
}
