import { ChangeEvent } from "react";
import { defaultFor } from "common";
import { validatePassword } from "common/api/authentication/user";
import { getError } from "common/api/error";
import { Culture } from "common/culture/supported-cultures";
import { RecoverLink } from "common/explicit-auth/forgot-password";
import { useResetEmail } from "common/explicit-auth/use-reset-email";
import { HorizontalField, Section } from "common/form/ui";
import { hasPermissionToUpdate } from "common/functions/roles";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { PasswordPolicy } from "common/types/settings";
import { UiFormat } from "common/types/ui-format";
import { showErrorNotification } from "common/ui/notification";
import { email } from "common/validate";
import { AlertWarning } from "common/widgets/alert";
import { Email } from "common/widgets/email";
import { ImageWithUpload } from "common/widgets/image-with-upload";
import { StringInput } from "common/widgets/input-with-submit/string";
import { ValueProps } from "common/with-value-for";
import { T1000 } from "common/widgets/text";
import { LazyPhone } from "common/widgets/phone";
import { CultureSettings } from "x/culture/culture-settings";
import { UserDetails } from "../types";

export type UploadAvatar = (file: FileType) => CancellablePromise<any>;
export type RemoveAvatar = () => void;

interface PropTypes extends ValueProps<UserDetails> {
  cultures: Culture[];
  passwordPolicy: PasswordPolicy;
  uploadAvatar: UploadAvatar;
  removeAvatar: RemoveAvatar;
  context: Context;
}

const passwordsMatch = (value: UserDetails): boolean =>
  value?.password === value.confirmPassword;

export const validDetails = (
  value: UserDetails,
  passwordPolicy: PasswordPolicy,
): boolean =>
  value &&
  email(value.userName) &&
  !validatePassword(passwordPolicy, value.password, value.userName) &&
  passwordsMatch(value);

export const Details = ({
  value = defaultFor<UserDetails>(),
  context,
  uploadAvatar,
  removeAvatar,
  cultures = [],
  passwordPolicy,
  onChange,
}: PropTypes) => {
  const {
    name,
    image,
    userName,
    currentPassword,
    password,
    confirmPassword,
    uiFormat,
    userContactInfo,
  } = value;

  const { resetPasswordError, isSent, onSendClick } = useResetEmail(context);

  const onImageNotFound = () => {
    onChange({ ...value, image: undefined });
  };

  const onNameChange = (name: string) => {
    onChange({ ...value, name });
  };

  const onCurrentPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      currentPassword: e.target.value,
    });
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, password: e.target.value });
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      confirmPassword: e.target.value,
    });
  };

  const onImageChange = (image: string) => {
    onChange({ ...value, image });
  };

  const onUserNameChange = (userName: string) => {
    onChange({ ...value, userName });
  };

  const onUiFormatChange = (uiFormat: UiFormat) => {
    onChange({ ...value, uiFormat });
  };

  const onLocationChange = (location: string) => {
    onChange({
      ...value,
      userContactInfo: {
        ...userContactInfo,
        location,
      },
    });
  };

  const onJobTitleChange = (jobTitle: string) => {
    onChange({
      ...value,
      userContactInfo: {
        ...userContactInfo,
        jobTitle,
      },
    });
  };

  const onDepartmentChange = (department: string) => {
    onChange({
      ...value,
      userContactInfo: {
        ...userContactInfo,
        department,
      },
    });
  };

  const onPhoneChange = (phone: string) => {
    onChange({
      ...value,
      userContactInfo: {
        ...userContactInfo,
        phone,
      },
    });
  };

  const onBusinessAddressChange = (businessAddress: string) => {
    onChange({
      ...value,
      userContactInfo: {
        ...userContactInfo,
        businessAddress,
      },
    });
  };

  const currentPasswordError =
    !currentPassword && (password || confirmPassword);

  const passwordPolicyError = validatePassword(
    passwordPolicy,
    password,
    userName,
  );

  const passwordConfirmError = confirmPassword && !passwordsMatch(value);
  const canUpdate = hasPermissionToUpdate(
    context.userTypes,
    context.role,
    "ProfileSettings",
  );

  return (
    <div className="x-user-profile-details">
      <div className="row">
        <div className="col-md-3 col-lg-2 x-image-uploader-wrapper">
          <ImageWithUpload
            context={context}
            allowClear={true}
            alt={_("User Avatar")}
            isLarge={true}
            onUpload={uploadAvatar}
            onRemove={removeAvatar}
            onError={onImageNotFound}
            value={image}
            onChange={onImageChange}
          />
        </div>
        <div className="row col-md-9 col-lg-10">
          <div className="col-md-6">
            <Section legend={_("Personal Details")} className="x-section">
              <HorizontalField
                className="qa-name"
                label={_("Name")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Name")}
                  value={name || ""}
                  onChange={onNameChange}
                />
              </HorizontalField>
              <HorizontalField
                disabled={!canUpdate}
                className="qa-email"
                label={_("Email (Username)")}
                error={!userName || !email(userName)}
              >
                <Email
                  value={userName}
                  validate={true}
                  onChange={onUserNameChange}
                />
              </HorizontalField>
            </Section>
          </div>
          <div className="col-md-6">
            <Section legend={_("Security")} className="x-section">
              {context.isPasswordExpired && (
                <AlertWarning
                  message={_(
                    "Your eMaint X5 password has expired. Please update your password and click save. An updated password is required for continued use of your account.",
                  )}
                />
              )}
              <div className="x-confirmPassword-wrapper">
                {passwordPolicyError && (
                  <span className="x-confirmPassword-no-match">
                    {passwordPolicyError}
                  </span>
                )}
                <HorizontalField label={_("Password")}>
                  <form>
                    {" "}
                    {/* needs form tag for autoComplete to work ATM */}
                    {/* TODO create a password widget */}
                    <input
                      type="password"
                      className="qa-password"
                      placeholder={_("New password")}
                      autoComplete="off"
                      value={password || ""}
                      onChange={onPasswordChange}
                    />
                  </form>
                </HorizontalField>
              </div>
              <div className="x-confirmPassword-wrapper">
                {passwordConfirmError && (
                  <span className="x-confirmPassword-no-match">
                    {_("Passwords do not match")}
                  </span>
                )}
                <HorizontalField label={_("Confirm password")}>
                  <form>
                    {" "}
                    {/* needs form tag for autoComplete to work ATM */}
                    {/* TODO create a password widget */}
                    <input
                      type="password"
                      className="qa-confirm-password"
                      placeholder={_("Confirm new password")}
                      autoComplete="off"
                      value={confirmPassword || ""}
                      onChange={onConfirmPasswordChange}
                    />
                  </form>
                </HorizontalField>
              </div>
              <div className="x-confirmPassword-wrapper">
                {currentPasswordError && (
                  <span className="x-confirmPassword-no-match">
                    {_("Current password is required")}
                  </span>
                )}
                <HorizontalField label={_("Current password")}>
                  <form>
                    {" "}
                    {/* needs form tag for autoComplete to work ATM */}
                    {/* TODO create a password widget */}
                    <input
                      type="password"
                      className="qa-password"
                      placeholder={_("Current password")}
                      autoComplete="off"
                      value={currentPassword || ""}
                      onChange={onCurrentPasswordChange}
                    />
                  </form>
                </HorizontalField>
                <RecoverLink onSendClick={onSendClick} isSent={isSent} />
                {resetPasswordError &&
                  showErrorNotification(getError(resetPasswordError))}
              </div>
            </Section>
          </div>
          <div className="col-md-6">
            <Section legend={_("Company")} className="x-section">
              <HorizontalField
                className="qa-primary-location"
                label={_("Primary Location")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Primary Location")}
                  value={userContactInfo?.location}
                  onChange={onLocationChange}
                />
              </HorizontalField>
              <HorizontalField
                className="qa-job-title"
                label={_("Job Title")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Job Title")}
                  value={userContactInfo?.jobTitle}
                  onChange={onJobTitleChange}
                />
              </HorizontalField>
              <HorizontalField
                className="qa-department"
                label={_("Department")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Department")}
                  value={userContactInfo?.department}
                  onChange={onDepartmentChange}
                />
              </HorizontalField>
              <HorizontalField label={_("Phone Number")} disabled={!canUpdate}>
                <LazyPhone
                  value={userContactInfo?.phone}
                  onChange={onPhoneChange}
                />
              </HorizontalField>
              <HorizontalField
                label={_("Business Address")}
                disabled={!canUpdate}
              >
                <T1000
                  placeholder={_("Business Address")}
                  value={userContactInfo?.businessAddress}
                  onChange={onBusinessAddressChange}
                />
              </HorizontalField>
            </Section>
          </div>
          <div className="col-md-6">
            <Section className="x-section" legend={_("Preferences")}>
              <CultureSettings
                cultures={cultures}
                value={uiFormat}
                onChange={onUiFormatChange}
              />
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};
