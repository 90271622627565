export const getActionIcon = (action: string) => {
  switch (action.toLowerCase()) {
    case "accept":
      return "fa-check";
    case "adjust":
      return "fa-sliders";
    case "approve":
      return "fa-thumbs-o-up";
    case "close":
      return "fa-folder";
    case "create":
      return "fa-plus-circle";
    case "delete":
      return "fa-trash-o";
    case "email":
      return "fa-envelope-o";
    case "exporttocsv":
      return "fa-file-code-o";
    case "exporttopdf":
      return "fa-file-pdf-o";
    case "importcsv":
      return "fa-file-o";
    case "open":
      return "fa-folder-open-o";
    case "reject":
      return "fa-thumbs-o-down";
    case "reopen":
      return "fa-folder-open";
    case "reschedule":
      return "fa-calendar";
    case "restore":
      return "fa-reply";
    case "setdefault":
      return "fa-check-square-o x-flex-center-center";
    case "sign":
      return "fa-pencil";
    case "transfer":
      return "fa-exchange";
    case "update":
      return "fa-pencil-square-o x-flex-center-center";
    case "select":
      return "fa-plus x-padding-10 x-link-color";
    case "createworkorder":
      return "fa-wrench";
    default:
      return "fa-flash";
  }
};

export const getActionTitle = (action: string) => {
  const actionKey = action.toLowerCase();
  switch (actionKey) {
    case "accept":
      return _("Accept");
    case "adjust":
      return _("Adjust");
    case "addcomments":
      return _("Add Comments");
    case "approve":
      return _("Approve");
    case "cancelpartcount":
      return _("Cancel Count");
    case "close":
      return _("Close");
    case "create":
      return _("Create");
    case "delete":
      return _("Delete");
    case "email":
      return _("Email");
    case "exporttocsv":
      return _("Export to CSV");
    case "exporttopdf":
      return _("Export to PDF");
    case "importcsv":
      return _("Import CSV");
    case "open":
      return _("Open");
    case "reject":
      return _("Reject");
    case "reopen":
      return _("Reopen");
    case "reschedule":
      return _("Reschedule");
    case "restore":
      return _("Restore");
    case "setdefault":
      return _("Set Default");
    case "submitpartcount":
      return _("Submit Count");
    case "submitpartscount":
      return _("Submit Ready Counts");
    case "switchform":
      return _("Switch Form");
    case "sign":
      return _("Sign");
    case "transfer":
      return _("Transfer");
    case "update":
      return _("Update");
    case "select":
      return _("Select");
    case "createworkorder":
      return _("Create Work Order");
    default:
      return action;
  }
};
